<template>
  <div
    class="type-checkbox"
    :class="{ 'component-error': hasError }"
    @click="question.answerText = !question.answerText"
  >
    <div class="question-body">
      <mem-checkbox
        theme="light"
        :hasError="hasError"
        v-model="question.answerText"
      ></mem-checkbox>
      <div>{{ question.questionText }}</div>
    </div>
    <div class="question-info" v-html="question.moreInfoContents"></div>
  </div>
</template>
<script>
export default {
  components: {
    "mem-checkbox": () => import("@/components/base/BaseCheckbox.vue"),
  },
  data: () => ({}),
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError() {
      return (
        this.validate && this.question.required && !this.question.answerText
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.type-checkbox {
  .question-body {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: flex-start;
    font-size: 12px;
    line-height: 18px;
    @include cursorPointer;
    .mem-checkbox {
      margin-right: 8px;
    }
  }
  .question-info {
    margin-left: 26px;
    // font-size: 12px;
    ::v-deep(a) {
      color: #000000;
      text-decoration: none;
    }
  }
  .component-error {
    color: #ff0707;
  }
}
</style>