<template>
  <div class="type-text">
    <v-text-field
      light
      color="#8D8D8D"
      class="mem-text-field"
      :label="question.questionText"
      v-model="question.answerText"
      :error-messages="errors"
    ></v-text-field>
    <div class="question-info" v-html="question.moreInfoContents"></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    errors: [],
  }),
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError() {
      return (
        this.validate && this.question.required && !this.question.answerText
      );
    },
  },
  watch: {
    hasError: {
      handler(val) {
        if (val) return this.errors.push(this.$t("field_required"));
        this.errors = [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.type-text {
  .mem-text-field {
    max-width: 284px;
    @media screen and (max-width: $mobile-view) {
      // max-width: 204px;
    }
    ::v-deep(.v-label) {
      text-transform: capitalize;
      font-weight: 500;
      color: #8d8d8d;
    }
  }
  .question-info {
    font-size: 12px;
    ::v-deep(a) {
      color: #000000;
      text-decoration: none;
    }
  }
}
</style>