<template>
  <v-select
    class="type-multiple"
    light
    v-model="question.answerId"
    :label="question.questionText"
    :items="question.questionAnswers"
    item-text="answer"
    item-value="id"
    color="#4F4F4F"
    :error-messages="errors"
  ></v-select>
</template>
<script>
import { find } from "lodash";
export default {
  data: () => ({
    errors: [],
  }),
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError() {
      return (
        this.validate && this.question.required && !this.question.answerText
      );
    },
  },
  methods: {},
  watch: {
    "question.answerId": {
      handler(id) {
        let result = find(this.question.questionAnswers, { id });
        this.question.answerText = result.answer;
      },
    },
    hasError: {
      handler(val) {
        if (val) return this.errors.push(this.$t("field_required"));
        this.errors = [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.type-multiple {
  ::v-deep(.v-label) {
    text-transform: capitalize;
    font-weight: 500;
    color: #4f4f4f;
  }
}
</style>