<template>
  <div class="questions-list">
    <div
      class="question-item"
      v-for="(question, index) in questionsList"
      :key="index"
    >
      <component
        :is="question.componentName"
        :question="question"
        :validate="validateList"
      />
    </div>
  </div>
</template>
<script>
import CustomerQuestionTypeText from "./CustomerQuestionTypeText.vue";
import CustomerQuestionTypeCheckbox from "./CustomerQuestionTypeCheckbox.vue";
import CustomerQuestionTypeMultiple from "./CustomerQuestionTypeMultiple.vue";
export default {
  components: {
    CustomerQuestionTypeText,
    CustomerQuestionTypeCheckbox,
    CustomerQuestionTypeMultiple,
  },
  props: {
    questionsList: {
      type: Array,
      default: () => [],
    },
    validateList: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.questions-list {
  padding-top: 30px;
  .question-item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    .type-text {
    }
    .type-multiple {
    }
  }
}
</style>